.itineraryTbl, .mmdTbl {
    min-height: calc(80vh - 20px);
    height: 100%;
    width: 100%;
    margin-top: 10px;
    background-color: $primary-white;
    border-radius: 1em;
    overflow-x: auto;

    table{
        height: 1vh;
        width: 100%;
        table-layout: fixed;
        // background-color: $primary-white;
        // border-radius: 1em;
        cursor: pointer;
        thead{
            border-bottom: 2px solid $quaternary-blue;
            font-size: $primary-font-size;
            color: $primary-blue;
            tr{

                th{
                    height: 60px;
                    width: 194px;
                    padding-left: 20px;
                    color: $primary-blue;
                    font-weight: lighter;
                    font-family: 'Trade Gothic', serif;
                    &.sorted {
                        background-color: #ECF7F8;
                    }

                    span {
                        padding-left: 5px;
                    }

                    .sorted, .unsorted {
                        margin-right: 5px;
                    }
                    
                    .no-sort{
                        padding-left: 17px;
                    }
                }
                         
            }
        }
        
        tbody{
            font-size: $primary-font-size;
            color: $secondary-color;

            .table {
                &-no-data {
                    color: $primary-color;
                    position: absolute;
                    left: 60%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                }
            }

            tr{
                td{
                    height: 60px;
                    width: 20%;
                    padding-left: 37px;
                    white-space: normal;
                    word-wrap: break-word;
                }
                
                .upd-loc {
                    color: $tertiary-color;
                }

                &:nth-child(odd){
                    background-color: #F7F7F7;
                }
                &:nth-child(even){
                    background-color: #fff;  
                }
            }

            tr:hover {
                // background-color: $primary-selected;
                //background-color: $primray-sky;
                background-color: #ECF7F8;
            }
          
        }
        tfoot{
            tr{
                td{
                    padding-left: 27px;
                }
            }
        }
    }
}