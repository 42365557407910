
@font-face {
    font-family: "Trade Gothic Bold";
    src: url("../../../../assets/fonts/TradeGothicLTBold/Trade-Gothic-LT-Bold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: 'Trade Gothic';
    src: url('../../../../assets/fonts/TradeGothicLT/Trade-Gothic-LT.ttf') format('truetype');
    font-weight: normal;
}
// @font-face {
//     font-family: "Trade Gothic";
//     src: url("../../../../assets/fonts/TradeGothicLTLight/Trade-Gothic-LT-Light.ttf");
//     font-weight: lighter;
// }

// .font-bold{
//     font-weight: bold;
// }

// .font-semibold{
//     font-weight: 600;
// }

// .font-italic {
//     font-style: italic;
// }

// .font-underline {
//     text-decoration: underline;
// }

// .font-line-through {
//     text-decoration: line-through;
// }

// .font-overline {
//     text-decoration: overline;
// }

// .header-placeholder{
//     font-size: 22px;
//     color:$primary-color;
// }

// .placeholder{
//     font-size: $primary-font-size;
//     color:$primary-color;
// }

// .header-cbmCrumbs{
//     color:$primary-color;
//     opacity: 60%;
//     cursor: pointer;
// }
// .header-cbmName{
//     color:$primary-color;
// }

// span.label{
//     font-size:$secondary-font-size;
//     color: $secondary-color;
// }

// span.info, span.data{
//     text-transform: capitalize;
//     font-size:$secondary-font-size;
//     color: $primary-black;
// }

// span.remarks{
//     font-size:$secondary-font-size;
//     color: $primary-black;
// }
body, html , *{
    font-family: 'Trade Gothic', serif;
}
.header{
    font-size: $tertiary-font-size;
    // color: $primary-green;
    color: $primary-blue;
    font-weight: bold;
    font-family: 'Trade Gothic Bold', serif;
}

.meterLbl{
    color: $primary-color;
}

.meterData{
    color: $black;
    margin-bottom: 10px;
    font-weight: 800;
}

.imgLbl{
    color: $primary-blue;
    cursor: pointer;
    //display: block;
    margin-bottom: 10px;

}

.imgLblD{
    color: $primary-color;
    display: block;
    margin-bottom: 10px;
}

.longLat{
    color: $primary-blue;
    cursor: pointer;
    display: block;
    margin-bottom: 10px;

    .updateLbl{
        color: $tertiary-color;
    }
}

.passwordCriteriaInvalid{
    margin-bottom: 0px;
    font-style: italic;
    font-size: 10px!important; 
    color: red;
}
.passwordCriteriaValid{
    margin-bottom: 0px;
    font-style: italic;
    font-size: 10px!important; 
    color: green;
}