@import "global/_library.scss";

@import "global/layouts/alignments.scss";
@import "global/layouts/containers.scss";
// @import "global/layouts/sidenav.scss";
@import "global/layouts/size.scss";
// @import "global/layouts/nav.scss";
// @import "global/layouts/sidenav.scss";

@import "global/utils/colors.scss";
@import "global/utils/error-handling.scss";
@import "global/utils/fonts.scss";


@import "global/html_tags/button.scss";
@import "global/html_tags/input.scss";
// @import "global/html_tags/ng-autocomplete.scss";
@import "global/html_tags/select.scss";
@import "global/html_tags/table.scss";
@import "global/html_tags//div.scss";

// @import "../scss/htt-scss/global.scss";
// @import "../scss/htt-scss/homepage/content.scss";
// @import "../scss/htt-scss/homepage/homepage.scss";
// @import "../scss/htt-scss/homepage/iteneraries-list.scss";
// @import "../scss/htt-scss/homepage/details.scss";
// @import "../scss/htt-scss/homepage/modal.scss";


// body, html {
//     font-size: $secondary-font-size;

//     label {
//         margin-bottom: unset;
//     }
// }

@import "~@angular/material/prebuilt-themes/indigo-pink.css";