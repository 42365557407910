// button {
//     width: 100%;
//     // padding: $padding-button;

//     border-radius: $border-radius;
//     border: 1px solid $primary-theme;
//     outline: none !important;

//     font-family: 'Open Sans', sans-serif;
// }

// .form-actions-btn {
//     margin-top: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;

//     button {
//         height: 40px;
//         border-radius: 20px;
//         width: 250px;
//         padding: 0;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         // border: 1px solid;
//         margin-right: 10px;
//         transition: all .5s ease-in-out;

//         &:last-child {
//             margin-right: 0;
//         }
//     }
//     &-primary {
//         background-color:$primary-color;
//         color: $primary-white;
//         border-color: $primary-color;

//         &:hover {
//             box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
//         }
//     }
//     &-secondary {
//         background-color: $primary-white;
//         color:$primary-color;
//         border-color: $primary-color;

//         &:hover {
//             background-color: $primary-color;
//             color: $primary-white;
//             box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
//         }
//     }
// }

// .table-filter {
//     &-btn {
//         background-color: transparent;
//         border: none;
//         border-radius: 4px;
//         display: flex;
//         padding: 10px;

//         &:hover {
//             background-color: #f5f5f5;
//         }
//     }
//     &-icon {
//         height: 14px;
//         width: 14px;
//     }
// }
// .table-create {
//     &-button {
//         background-color: $primary-color;
//         color: $primary-white;
//         padding: 0;
//         height: 40px;
//         width: 250px;
//         font-size: 15px;
//         justify-content: center;
//         border-radius: 20px;
//         display: flex;
//         align-items: center;
//         border: 1px solid $primary-color;

//         &:hover {
//             box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
//         }
//     }
// }

// .primary-button {
//     background-color:$primary-color;
//     color: $primary-white;
//     border-radius: 2em;
//     border-color: $primary-color;
// }

// .primary-button:hover {
//     box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
// }

// button:disabled, button:disabled:hover {
//     background-color: $tertiary-gray;
//     border-color: $tertiary-gray;
//     box-shadow: none;
// }

// .secondary-button {
//     background-color: $primary-white;
//     color:$primary-color;
//     border-radius: 2em;
//     border-color: $primary-color;
// }

// .secondary-button:hover {
//     background-color: $primary-color;
//     color: $primary-white;
// }

// .navbar .navbar-toggler {
//     color: white !important;
// }

// .navbar .navbar-toggler-bar {
//     background: white !important;
// }

// .navbar.navbar-transparent .nav-item .nav-link{
//     // mix-blend-mode: screen;
//     color: white !important;
// }

// .navbar .navbar-toggler:active {
//     color: white !important;
// }

// .navbar .navbar-toggler-bar:active {
//     background: white !important;
// }
.paginationBtn {
    
    .ngx-pagination{
        
        .current{
            background: $primary-green;
            border-radius: 50%;
        }

        li{
            background: $primary-theme;
            border-radius: 50%;
        }

        .pagination-previous, .pagination-next{
            background: none;
            color: $primary-theme;
        }
    } 
}

.ngb-pagination{
    .pagination {
        justify-content: center;
    }
    li {
        &:first-child {
            &.disabled {
                display: none;
            }
            a{
                color: $primary-black;
                background-color: $primary-white;
                border: none;
                box-shadow: none;

                &:before {
                    content: 'First';
                }
            }
        }
        &:nth-child(2) a{
            color: $primary-black;
            background-color: $primary-white;
            border: none;
            box-shadow: none;
            
            &:before {
                content: 'Previous';
            }
        }
        &:last-child a{
            color: $primary-black;
            background-color: $primary-white;
            border: none;
            box-shadow: none;
            
            &:before {
                content: 'Last';
            }
        }
        &:nth-last-child(2) a{
            color: $primary-black;
            background-color: $primary-white;
            border: none;
            box-shadow: none;

            &:before {
                content: 'Next';
            }
        }
        //page numbers
        &:not(:first-child):not(:last-child):not(:nth-child(2)):not(:nth-last-child(2)){ 
            a{
                height: 27px;
                min-width: 27px;
                justify-content: center;
                border-radius: 27px;
                color: $primary-blue;
                border-color: transparent;
                background-color: #EEEFFA;
                font-size: 14px;
                display: flex;
                align-items: center;
                padding: 0 5px;

                &:hover {
                    background-color: $primary-blue;
                    color: $primary-white;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                }
            }
            
            &.disabled {
                a {
                    background-color: transparent;
                    color: $primary-gray;
                }
            }

            &.active a{
                background-color: $primary-blue;
                color: $primary-white;
                box-shadow: none;
            }
        }
        &:first-child, &:last-child, &:nth-child(2), &:nth-last-child(2) {
            a {
                height: 27px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                font-size: 16px;
                color: rgba(23, 28, 143, 0.44);

                span {
                    display: none;
                }

                &:hover {
                    color: $primary-blue;
                }
                &:active{
                    box-shadow: none;
                    border-color: transparent;
                }
                &:focus {
                    box-shadow: none;
                }
            }
            &.disabled {
                a {
                    color: $primary-lightgray;
                }
            }
        }
    }
    .page-item{
        margin: 5px;
        .disabled{
            a{
                &:before {
                    color: $primary-theme;
                }
            }
        }
    }
}

.filterMetersIcon{
    margin-left: 10px;
    cursor: pointer;
}

button.primary-btn{
    background: $primary-blue;
    color: $primary-white;
    padding: 10px;
    border-radius: 10px;

    &.bg-primary-80 {
        background: $primary-blue-80;
    }

    &:hover {
        color: $primary-white;
    }
}

button.transparent-btn{
    background: transparent;
    color: $primary-blue;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $primary-blue;
    
    &:hover{
            color: $primary-blue;
    }
}


button.text-btn{
    background: transparent;
    color: $primary-blue;
    padding: 10px;
    border-radius: 10px;
    border: none;
    
    &:hover{
        color: $primary-blue;
    }
}
button.mdl-btn{
    padding: 10px 30px;
    border-radius: 5px;
}

button.btn:disabled {
    background-color: #E1E0E0;
    border-color: #E1E0E0;
    color: #999999;
    opacity: 1;
}
