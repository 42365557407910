// input, textarea {
//     font-family: 'Open Sans', sans-serif;
//     width: 100%;
//     padding: $padding;
//     background-color: $primary-white;
//     border: 1px solid $tertiary-white;
//     border-radius: 1.33em;
//     outline: none;
//     margin: 0.33em 0em 0.33em 0em;
// }

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
// -webkit-appearance: none;
// margin: 0;
// }

// /* Firefox */
// input[type=number], input[type=date] {
// -moz-appearance:textfield;
// }

// .primary-input {
//     border: solid 2px $primary-theme;
// }

// .secondary-input {
//     border: solid 1px $primary-theme;
// }

// input[type=radio]{
//     width: initial;
//     transform: scale(1.3);
// }

// .input-normal {
//     input:disabled, textarea:disabled {
//         border: solid 1px $tertiary-white !important;
//         border-radius: 1.33em;
//         background-color: $tertiary-white;
//         color: $primary-black;
//     }
//     textarea:disabled {
//         border-radius: 1.33em;
//     }
    
//     input:focus, textarea:focus {
//         border: solid 1px $primary-color !important;
//     }
    
// }
input{
    
    &:focus{
        outline: none;
    }
}
.searchBar{
    margin: 20px 0px 20px 20px;
    .searchItinerary{
        background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
        height: 60px;
        width: 380px;
        padding: 20px 0px 20px 60px;
        border: 1px solid $primary-gray;
        border-radius: 1.875em;
        background-color: $primary-white !important;
    
        // font: italic normal 300 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: $primary-color;
        opacity: 1;
    }
}
.uploadALO{
    margin: 20px 0px 20px 20px;
    .uploadALO30{
        
        height: 60px;
        width: 380px;
        padding: 20px 0px 20px 60px;
        border: 1px solid $tertiary-green;
        border-radius: 1.875em;
        background-color: $primary-white !important;
    
        // font: italic normal 300 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: $tertiary-green;
        opacity: 1;
    }
}

.mmdSearchBar{
    margin: 20px 0px 0px 0px;
    .searchItinerary{
        background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
        height: 60px;
        width: 380px;
        padding: 20px 0px 20px 60px;
        border: 1px solid $primary-gray;
        border-radius: 1.875em;
        background-color: $primary-white !important;
    
        // font: italic normal 300 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: $tertiary-green;
        opacity: 1;
    }
}

.mmdTextBox{
    margin: 20px 0px 20px 0px;
    .txtBox{
        //background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
        height: 30px;
        width: 380px;
        padding: 20px 0px 20px 60px;
        border: 1px solid $tertiary-green;
        border-radius: 1.875em;
        background-color: $primary-white !important;
    
        // font: italic normal 300 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: $tertiary-green;
        opacity: 1;
    }
}

.hht-mat-select-input{
    margin: 0;

    .mat-form-field-wrapper{
        border-bottom: none;
    }

    .mat-form-field-flex {
        border: 1px solid $secondary-gray;
        border-radius: 10px !important;
        background-color: $primary-white;
        height: 60px;
        padding: 18px !important;
    }

    .mat-form-field-underline{

        &::before, &:hover::before{
            height: 0px !important;
        }
    }
    .mat-form-field-ripple{
        background-color: transparent !important;
    }
    .mat-form-field-infix{
        padding: 0 !important;
        border: 0 !important;
    }
    .ng-star-inserted{
        font-size: 16px;
        line-height: 25px;
        font-family: 'Trade Gothic', serif;
        color: $primary-blue;
    }
    .mat-select-arrow{
        background-image: url('../../../img/icons/icon-blue-caret-down.svg') !important;
        background-repeat: no-repeat;
        background-position: center;
        height: 20px !important;
        width: 20px !important;
        margin-top: 18px;
        border: none !important;
    }

    &.select-sm{
        .mat-form-field-flex {
            border: 1px solid $secondary-gray;
            border-radius: 10px !important;
            background-color: $primary-white;
            height: 48px;
            padding: 10px 18px !important;
        }
        .mat-select-arrow{
            margin-top: 30px;
        }
    }
}
.hht-mat-select{
    margin: 0 10px;

    .mat-form-field-wrapper{
        border-bottom: none;
    }

    .mat-form-field-flex {
        border: 1px solid $secondary-gray;
        border-radius: 50px !important;
        background-color: $primary-white;
        height: 60px;
        padding: 18px !important;
    }

    .mat-form-field-underline{

        &::before, &:hover::before{
            height: 0px !important;
        }
    }
    .mat-form-field-ripple{
        background-color: transparent !important;
    }
    .mat-form-field-infix{
        padding: 0 !important;
        border: 0 !important;
    }
    .ng-star-inserted{
        font-size: 20px;
        font-family: 'Trade Gothic', serif;
        color: $primary-blue;
    }
    .mat-select-arrow{
        background-image: url('../../../img/icons/icon-blue-caret-down.svg') !important;
        background-repeat: no-repeat;
        background-position: center;
        height: 20px !important;
        width: 20px !important;
        margin-top: 18px;
        border: none !important;
    }

    .mat-select-placeholder {
        color: #CCCCCC;
    }

    &.select-sm{
        .mat-form-field-flex {
            border: 1px solid $secondary-gray;
            border-radius: 50px !important;
            background-color: $primary-white;
            height: 48px;
            padding: 10px 18px !important;
        }
        .mat-select-arrow{
            margin-top: 30px;
        }
    }

    &.border-radius-10 {
        .mat-form-field-flex {
            border-radius: 10px !important;
        }
    }

    &.wrapper-p-0 {
        .mat-form-field-wrapper {
            padding: 0;
        }
    }
}

.hht-input-text{
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $primary-gray;
        width: 290px;

      
}

.dropdown-option{
    cursor: pointer;
    position: relative;
    input{
        cursor: pointer;
        width:100%
    }

    &::after{
        content: "";
        position: absolute;
        width: 65px;
        height: 100%;
        background-image: url('../../../img/icons/icon-blue-caret-down.svg') !important;
        background-position: center;
        background-repeat: no-repeat;
        right: 0;
    }
}
.hht-autocomplete{
    mat-form-field{
        width: 100%;

        .mat-form-field-infix{
            position: relative;
            box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
            &::after{
                content: "";
                position: absolute;
                left: 0;
                width: 50px;
                height: 30px;
                top: 0;
                background-image: url('../../../img/icons/icon-search.svg');
                background-repeat: no-repeat;
                background-position: center;
            }

            input{
                padding-left: 50px;
            }
            .ng-star-inserted{
                padding-left: 25px;
            }
        }
    }
}
.hht-form-control{
    position: relative;
    padding: 10px;
    label{
        display: block;
        padding-bottom: 5px;
        &.required:after {
            content: '*';
            color: #EE0000;
        }
    }
    .invalid-label {
        color: red !important;
    }
    .invalid{
        border-color: red;
    }
    .validation-message {
        position: absolute;
        color: red;
        top: 93%;
        font-size: 15px;
    }
    
}

.cdk-overlay-container{
    z-index: 9999999 !important;
}

::placeholder {
    color: $quaternary-gray;
}


.custom-input-rc{
    .switch {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 24px;
    }
    
    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
    
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #C3C3C3;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    
    input:checked + .slider {
        background-color: #171C8F;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 1px #E0E1F4;
    }
    
    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    
    .slider.round {
        border-radius: 34px;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }


    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 23px;
        width: 23px;
        background-color: #eee;
        border-radius: 50%;
        border: 1px solid #BEF1F3;
    }
    
    .container:hover input ~ .checkmark {
        background-color: #fff;
        border: 1px solid #BEF1F3;
    }
    
    .container input:checked ~ .checkmark {
        background-color: #171C8F;
    }
    
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .container input:checked ~ .checkmark:after {
        display: block;
    }
    
    .container .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}

.custom-input-checkbox{
        /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 23px;
        width: 23px;
        background-color: #eee;
        border: 1px solid #BEF1F3;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }
    
    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: $primary-blue;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }
    
    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 8px;
        top: 4px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
}

input:read-only {
    background-color: #E1E0E0;
}

.mat-form-field-disabled {
    .mat-form-field-flex {
        background-color: #E1E0E0 !important;
    }
}


.form-group.required .control-label:after { 
    content:"*";
    color:red;
 }

 .description::before {
    content:"*";
    color:red;
 }

 .highlight-text {
    color: #171C8F !important;
}