.wrapper{
    height: 100vh;
    // height: 100%;
    // width: auto;

    .main-panel{
        height: 100%;
        width: 100%;
        display: flex;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        .sidebar{
            height: 80%;
            width: auto;
            min-height: calc(99vh - 10px);
            min-width: 300px;
            margin: 10px 10px 0px 10px;
            // background-color: $primary-green;
            background-color: #FDFDFD;
            border-radius: 16px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

            .sidebar-wrapper{
                height: 100%;
                width: 100%;
                
                .hht-logo{
                    height: auto;
                    width: auto;
                    // padding: 60px 85px 0px 85px;
                    padding-top: 60px;
                    text-align: center;
                    margin-bottom: 60px;

                    img{
                        height: auto;
                        width: auto;
                    }
                }

                .nav{
                    display: block;
                    margin: 0px 20px 0px 20px;
                    cursor: pointer;

                    li{
                        height: 60px;
                        padding: 18px 0px 0px 30px;
                        
                        .link{
                            outline: none;
                        }

                        &:first-child{
                            margin-bottom: 4px;
                        }
                        &:nth-child(2){
                            // margin-bottom: 20px;
                        }

                    }
                    .active{
                        // background-color: $secondary-green;
                        background-color: #D6F7F8;
                        border-radius: 30px;
                        box-shadow: 0px 10px 10px #0000000D;
                        box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.15);
                    }
                    .menuHover:hover{
                        // background-color: $secondary-green;
                        background-color: #D6F7F8;
                        border-radius: 30px;
                        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
                    }
                    a:hover{
                        text-decoration: none;
                    }
                    .menuItem{
                        // color: $quaternary-color;
                        font-family: 'Trade Gothic Bold';
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 25px;
                        color: #3B3B3B
                    }
                    .bottom-divider{
                        border-top: 2px solid #E0E1F4;
                        margin-top: 20px;
                        margin-bottom: 15px;
                        padding: 0;
                        max-height: 20px;

                        &:hover{
                            background: transparent;
                            box-shadow: none;
                            border-radius: 0;
                        }
                    }
                }
            }

            .footer{
                text-align: center;
                color: $primary-white;
                font-size: $quaternary-font-size;
                position: fixed;
                min-width: 300px;
                bottom: 8px;
                border-radius: 0px 0px 16px 16px;
                background: linear-gradient(90deg, #00B7BD 0%, #162291 100%);
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
        }

        .content{
            height: 97.5%;
            width: 100%;
            margin: 0px 10px 0px 10px;
            padding-top: 20px;
            min-height: 738px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .itinerary-content, .mmd-content{

                .tabsDiv{
                    background-color: $primary-white;
                    border-bottom: 1px solid $tertiary-green;
                    border-radius: 0 1em 0 0 ;
                }

                .itineraryDiv{
                    // padding: 0px 20px 0px 20px;
                    font-size: $primary-font-size;
                    background-color: $primary-white;
                    border-radius: 0 0 1em 1em;

                    .itineraryDetailsDiv{
                        padding: 20px 20px 20px 20px;
                        
                        .itineraryDetails{
                            display: flex;
                            margin-bottom: 10px;
                            
                            .itineraryData{
                                display: grid;
                            }
                        }

                        .metersLbl{
                            background-image: linear-gradient(to right, $secondary-purple 50%, rgba(255, 255, 255, 0) 50%);
                            background-position: top;
                            background-size: 30px 1px;
                            background-repeat: repeat-x;
                            color: $primary-blue;
                            font-family: 'Trade Gothic Bold', serif;
                            // border-top: 1px dashed $tertiary-green;
                            font-weight: 700;
                            padding-top: 20px;
                            margin-bottom: 10px;
                            font-size: 16px;
                        }
                    }

                    .mainMetersDiv{
                        // max-height: 42vh;
                        overflow-y: auto;
                        overflow-x: hidden;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .loading-spinner{
                            text-align: center;
                        }

                        .metersDiv{
                            padding: 30px 20px 10px 20px;
                            border-bottom: 1px solid $secondary-purple;
    
                            &:hover{
                                background-color: $primary-selected;
                            }

                            .meterDetailsDiv{
                                display: flex;
    
                                .meterDetails{
                                    display: inline-block;
                                    padding-bottom: 19px;
                                    .meterData{
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    .paginationDiv{
                        margin-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
        .filter-icon{
            background-image: url('../../../img/icons/icon-filter-blue.svg');
            width: 23px;
            height: 23px;
            float: left;
            margin-right: 10px;
            background-size: cover;
        }
        .filter-icon-selected{
            background-image: url('../../../img/icons/icon-filter-white.svg');
            width: 23px;
            height: 23px;
            float: left;
            margin-right: 10px;
            background-size: cover;
        }
   
   }
}
.ngx-toastr{
    background-color: $tertiary-purple !important;
    padding: 23px 42px 28px !important;
    max-width: 288px !important;
    border-radius: 10px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2) !important;
    &.toast-success{
        background: none;
    }
        .toast-title {
            font-family: 'Trade Gothic Bold';
            text-align: center;
            font-size: 24px;
            color: $primary-blue;
            padding-bottom: 25px;
        }
        .toast-message{
            text-align: center;
            font-size: 16px;
            color: $black;
            font-family: 'Trade Gothic';

        }

        .toast-close-button {
            position: relative;
            right: -1rem;
            top: -16px;
            float: right;
            font-size: 20px;
            font-weight: bold;
            color: $primary-blue;
            text-shadow: 0 1px 0 #ffffff;
            /* opacity: 0.8; */
        }
        .toast-progress{
            background-color: $primary-blue;
            opacity: 0.6;
        }
}
.pagination{
    margin: auto;
}

@media only screen and (max-width: 1600px) {
    .mainMetersDiv{
       max-height: 48vh !important;
  }
}

@media only screen and (max-width: 1430px) {
    .mainMetersDiv{
        max-height: 41vh !important;
    }
}

@media only screen and (max-width: 1290px) {
    .mainMetersDiv{
        max-height: 33vh !important;
    }
}

.modal {
    &.min-height-474 {
        .modal-content {
            min-height: 474px;
        }
    }
    &-content {
        /**min-width:  968px;
        min-height: 692px;
        padding: 100px 105px;*/
        height: 100%;
        width: 100%;
        box-shadow: 0px 6px 10px #00000014;
        border: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 16px !important;
        align-items: center;
        justify-content: center;

        // .carouselImg{
        //     height:100%;
        //     width: 100%;
        // }
    }
    &-carousel {
        height:100%;
        width: 100%;
        // height: 450px;
        // width: 450px;
        // display: flex;
        // flex-direction: column;
        // text-align: center;
        // justify-content: center;
        // align-items: center;
        
        .noImageLogo{
            height: 90px;
            width: 62px;
        }
        
        .carouselImg{
            height:100%;
            width: 100%;
        }
    }

    &-text-wrapper {
        // min-width: 417px;
        height: 100%;
        width: 100%;
        // padding-top: 180px;
        text-align: center;

        p {
            // font-family: 'Open Sans', sans-serif;
            color: $secondary-color;
            font-size: $primary-font-size;
            // margin-bottom: 30px;
        }
    }

    &-text-wrapper-info{
        min-width: 417px;
        width: 100%;
        text-align: center;
        font-family: 'Open Sans', sans-serif;

        .title {
            font-size: 22px;
            font-weight: bold;
            color: $primary-color;
            margin-bottom: 20px;
        }
        .info {
            font-size: 15px;
            color: $primary-black;
            margin-bottom: 20px;
        }
    }

    &-btn {
        &-primary {
            background-color: $primary-color;
            color: $primary-white;
            border-color: $primary-color;
            max-width: 250px;
            border-radius: 20px;
            font-size: 15px;
            width: 100%;
            height: 40px;
            margin-bottom:10px;
        }
        &-primary:hover {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }
        &-secondary {
            background-color: $primary-white;
            color: $primary-color;
            border-color: $primary-color;
            max-width: 250px;
            border-radius: 20px;
            font-size: 15px;
            width: 100%;
            height: 40px;
        }
        &-secondary:hover {
            background-color: $primary-color;
            color: $primary-white;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }
    }
}