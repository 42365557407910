.div-label {
    text-align: center;
    display: flex;
    align-items: center;
}

.container-white {
    margin-top: 0.5%;
    background-color: $primary-white;
    border-radius: 1em;
    overflow-x: auto;
}

// Utilities
.border-pill {
    border-radius: 9999px !important;
}

.mx--2 {
    margin-left: -8px  !important;
    margin-right: -8px  !important;
}

.height-60 {
    height: 60px !important;
}

.font-family-inherit {
    font-family: inherit;
}
